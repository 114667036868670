import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FunnelIcon, PencilSquareIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Button, Checkbox, DynamicPipelineModal, Input, TicketIdInput, StyledA } from '@src/components';
import {
  useCreateDynamicContentModConfig,
  useDeleteDynamicContentModConfig,
  useGetDynamicContentModConfigById,
  useGetDynamicPipelineModifiers,
  useGetDynamicPipelineSelectors,
  useUpdateDynamicContentModConfig
} from '@src/hooks';
import {
  AppliedModifierOrSelector,
  AppliedModifierSchema,
  AppliedSelectorSchema,
  PipelineSchema,
  DynamicContentModConfigSelectors,
  NewRuleType
} from '@src/models';
import routes from '@src/routes';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Switch } from '@headlessui/react';

export const DynamicPipelineForm = ({ isEdit }: { isEdit?: boolean }) => {
  const navigate = useNavigate();
  const { id: configId, type } = useParams() as { id: string, type: NewRuleType };

  const isDesktopCloneMode = type === NewRuleType.Desktop;

  const { mutateAsync: saveNewDynamicConfiguration } = useCreateDynamicContentModConfig();
  const { mutateAsync: updateContentModConfig } = useUpdateDynamicContentModConfig();
  const { mutateAsync: deleteContentModConfig } = useDeleteDynamicContentModConfig();

  const { data: dynamicConfiguration } = useGetDynamicContentModConfigById(configId);

  const [pipeline, setPipeline] = useState<PipelineSchema>({
    id: '',
    applyTo: '-',
    priority: undefined,
    description: undefined,
    ignoreGlobalConfig: false,
    selectors: [],
    modifiers: [],
    ticketId: ''
  });

  const [configurationSelectors, setConfigSelector] = useState<DynamicContentModConfigSelectors>({
    apply_to_recording: false,
    enabled: true,
    desktop_clone_mode: isDesktopCloneMode
  });

  const [modifierOrSelectorToEdit, setModifierOrSelectorToEdit] = useState<AppliedModifierOrSelector | undefined>();

  useEffect(() => {
    if (!dynamicConfiguration) return;

    setPipeline(dynamicConfiguration.configuration.pipelines[0]);
  }, [dynamicConfiguration]);

  useEffect(() => {
    if (!dynamicConfiguration) return;

    setConfigSelector(dynamicConfiguration.configuration_selectors);
  }, [dynamicConfiguration]);

  const [selectorsPaletteOpen, setSelectorsPaletteOpen] = useState(false);
  const [modifiersPaletteOpen, setModifiersPaletteOpen] = useState(false);
  const { data: availableSelectors, isLoading: selectorsLoading } = useGetDynamicPipelineSelectors(isDesktopCloneMode);
  const { data: availableModifiers, isLoading: modifiersLoading } = useGetDynamicPipelineModifiers(isDesktopCloneMode);

  useEffect(() => {
    if (selectorsPaletteOpen === false && modifiersPaletteOpen === false) {
      setTimeout(() => {
        setModifierOrSelectorToEdit(undefined);
      }, 200);
    }
  }, [selectorsPaletteOpen, modifiersPaletteOpen]);

  const saveConfiguration = () => {
    if (isEdit) {
      dynamicConfiguration &&
        updateContentModConfig({
          contentModConfigId: dynamicConfiguration.id,
          contentModConfig: {
            configuration: {
              pipelines: [pipeline],
              selectors: [],
              modifiers: [],
              global_config: []
            },
            configuration_selectors: {
              enabled: configurationSelectors.enabled,
              apply_to_recording: configurationSelectors.apply_to_recording,
              desktop_clone_mode: configurationSelectors.desktop_clone_mode
            }
          }
        }).then(() => navigate(routes.CONFIGURATIONS_DYNAMIC_PIPELINE));
    } else {
      saveNewDynamicConfiguration({
        configuration: {
          pipelines: [pipeline],
          selectors: [],
          modifiers: [],
          global_config: []
        },
        configuration_selectors: {
          enabled: configurationSelectors.enabled,
          apply_to_recording: configurationSelectors.apply_to_recording,
          desktop_clone_mode: configurationSelectors.desktop_clone_mode
        }
      }).then(() => navigate(routes.CONFIGURATIONS_DYNAMIC_PIPELINE));
    }
  };

  return (
    <div className="space-y-6 px-0 lg:px-6">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{`${type.charAt(0).toUpperCase()}${type.slice(1)}`} rule</h3>
            <p className="mt-1 text-sm text-gray-500">{`${type.charAt(0).toUpperCase()}${type.slice(1)}`} rule configuration</p>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form className="space-y-6" action="#" method="POST">
              <div className="col-span-6">
                <Input
                  label="Id"
                  type="text"
                  name="pipeline-id"
                  id="pipeline-id"
                  value={pipeline.id}
                  onChange={e => setPipeline({ ...pipeline, id: e.target.value })}
                />
              </div>

              <div className="grid grid-cols-3 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="apply-to" className="block text-sm font-medium text-gray-700">
                    Stage
                  </label>
                  <select
                    id="apply-to"
                    name="apply-to"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={pipeline.applyTo}
                    onChange={e => setPipeline({ ...pipeline, applyTo: e.target.value })}
                  >
                    <option value="-">-</option>
                    <option value="downstreamRequest">Downstream Request</option>
                    {!isDesktopCloneMode &&
                      <>
                        <option value="upstreamRequest">Upstream Request</option>
                        <option value="upstreamResponse">Upstream Response</option>
                      </>}
                    <option value="downstreamResponse">Downstream Response</option>
                  </select>
                </div>
              </div>

              <div className="col-span-6">
                <Input
                  label="Priority"
                  type="text"
                  name="priority"
                  id="priority"
                  value={pipeline.priority || ''}
                  onChange={e =>
                    setPipeline({ ...pipeline, priority: e.target.value ? parseInt(e.target.value) : undefined })
                  }
                />
              </div>
              <TicketIdInput
                value={pipeline.ticketId}
                onChange={(e: any) => setPipeline({ ...pipeline, ticketId: e.target.value })}
              />
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <div className="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    rows={3}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    value={pipeline.description || undefined}
                    onChange={e => setPipeline({ ...pipeline, description: e.target.value })}
                  />
                </div>
              </div>
              {!isDesktopCloneMode && <div className="flex flex-col gap-5">
                <Checkbox
                  label="Ignore Global Config"
                  description="Tick ✓ to include Desktop App"
                  isChecked={pipeline.ignoreGlobalConfig}
                  onChange={e => setPipeline({ ...pipeline, ignoreGlobalConfig: e })}
                />
              </div>}

              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={configurationSelectors.enabled}
                  onChange={is_enabled => setConfigSelector({ ...configurationSelectors, enabled: is_enabled })}
                  className={classNames(
                    configurationSelectors.enabled ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      configurationSelectors.enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-sm font-medium text-gray-900">Enabled</span>
                </Switch.Label>
              </Switch.Group>

              {!isDesktopCloneMode && <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={configurationSelectors.apply_to_recording}
                  onChange={is_clone_specific =>
                    setConfigSelector({ ...configurationSelectors, apply_to_recording: is_clone_specific })
                  }
                  className={classNames(
                    configurationSelectors.apply_to_recording ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      configurationSelectors.apply_to_recording ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-sm font-medium text-gray-900">Restrict to this Clone only</span>
                  <span className="text-sm text-gray-500">
                    {' '}
                    (Will not affect other Clones in the same organization)
                  </span>
                </Switch.Label>
              </Switch.Group>}
            </form>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Selectors</h3>
            <p className="mt-1 text-sm text-gray-500">
              Use selectors to match specific requests/responses. Allows you to filter the data by
              <i> request method</i>, <i>url</i>, <i>content</i> and much more.
            </p>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2 flex flex-col">
            <Button small icon={<PlusIcon />} className="self-end" onClick={() => setSelectorsPaletteOpen(true)}>
              Add
            </Button>
            <DynamicPipelineModal
              isOpen={selectorsPaletteOpen}
              onClose={() => setSelectorsPaletteOpen(false)}
              onSubmit={data => {
                if (data.internalId) {
                  // Update
                  const updateAtIndex = pipeline.selectors.findIndex(m => m.internalId === data.internalId);
                  if (updateAtIndex === -1) return;

                  const newSelectors = [...pipeline.selectors];
                  newSelectors[updateAtIndex] = data as AppliedSelectorSchema;
                  setPipeline({
                    ...pipeline,
                    selectors: newSelectors
                  });
                } else {
                  // New
                  data.internalId = uuidv4();
                  setPipeline({
                    ...pipeline,
                    selectors: [...pipeline.selectors, data as AppliedSelectorSchema]
                  });
                }

                setSelectorsPaletteOpen(false);
              }}
              isLoading={selectorsLoading}
              items={
                availableSelectors
                  ? Object.entries(availableSelectors).map(([modelName, obj]) => {
                    return {
                      modelName,
                      key: modelName,
                      id: obj.id,
                      name: obj.title,
                      description: obj.description,
                      color: 'bg-indigo-500',
                      icon: <FunnelIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    };
                  })
                  : []
              }
              modifierOrSelector={modifierOrSelectorToEdit}
            />
            <div className="py-4 sm:py-5">
              {pipeline.selectors.length > 0 && (
                <SelectorModifierList
                  items={pipeline.selectors.map(selector => ({
                    internalId: selector.internalId,
                    icon: <FunnelIcon className="h-6 w-6" aria-hidden="true" />,
                    text: selector.id,
                    actions: [
                      <StyledA
                        onClick={() => {
                          setModifierOrSelectorToEdit(
                            pipeline.selectors.find(s => s.internalId === selector.internalId)
                          );
                          setSelectorsPaletteOpen(true);
                        }}
                      >
                        Edit
                      </StyledA>,
                      <StyledA
                        onClick={() => {
                          if (window.confirm('Are you sure?')) {
                            setPipeline({
                              ...pipeline,
                              selectors: pipeline.selectors.filter(s => s.internalId !== selector.internalId)
                            });
                          }
                        }}
                      >
                        Delete
                      </StyledA>
                    ]
                  }))}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Modifiers</h3>
            <p className="mt-1 text-sm text-gray-500">
              Use modifiers to change matched requests/responses. Allows you to <i>modify content</i>,{' '}
              <i>remove/replace headers</i>, <i>fake response</i>, etc.
            </p>
          </div>
          <div className="mt-2 md:mt-0 md:col-span-2 flex flex-col">
            <Button small icon={<PlusIcon />} className="self-end" onClick={() => setModifiersPaletteOpen(true)}>
              Add
            </Button>
            <DynamicPipelineModal
              isOpen={modifiersPaletteOpen}
              onClose={() => setModifiersPaletteOpen(false)}
              onSubmit={data => {
                if (data.internalId) {
                  // Update
                  const updateAtIndex = pipeline.modifiers.findIndex(m => m.internalId === data.internalId);
                  if (updateAtIndex === -1) return;

                  const newModifiers = [...pipeline.modifiers];
                  newModifiers[updateAtIndex] = data;
                  setPipeline({
                    ...pipeline,
                    modifiers: newModifiers
                  });
                } else {
                  // New
                  data.internalId = uuidv4();
                  setPipeline({
                    ...pipeline,
                    modifiers: [...pipeline.modifiers, data as AppliedModifierSchema]
                  });
                }

                setModifiersPaletteOpen(false);
              }}
              isLoading={modifiersLoading}
              items={
                availableModifiers
                  ? Object.entries(availableModifiers).map(([modelName, obj]) => {
                    return {
                      modelName,
                      key: modelName,
                      id: obj.id,
                      name: obj.title,
                      description: obj.description,
                      color: 'bg-indigo-500',
                      icon: <PencilIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    };
                  })
                  : []
              }
              modifierOrSelector={modifierOrSelectorToEdit}
            />
            <div className="py-4 sm:py-5">
              {pipeline.modifiers.length > 0 && (
                <SelectorModifierList
                  items={pipeline.modifiers.map(modifier => ({
                    internalId: modifier.internalId,
                    icon: <PencilSquareIcon className="h-6 w-6" aria-hidden="true" />,
                    text: modifier.id,
                    actions: [
                      <StyledA
                        onClick={() => {
                          setModifierOrSelectorToEdit(
                            pipeline.modifiers.find(s => s.internalId === modifier.internalId)
                          );
                          setModifiersPaletteOpen(true);
                        }}
                      >
                        Edit
                      </StyledA>,
                      <StyledA
                        onClick={() => {
                          if (window.confirm('Are you sure?')) {
                            setPipeline({
                              ...pipeline,
                              modifiers: pipeline.modifiers.filter(s => s.internalId !== modifier.internalId)
                            });
                          }
                        }}
                      >
                        Delete
                      </StyledA>
                    ]
                  }))}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-5 sm:px-0 sm:p-6">
        <Button
          className={classNames('float-left', !isEdit && 'hidden')}
          danger
          onClick={() => {
            if (window.confirm('Are you sure?')) {
              deleteContentModConfig(configId);
              navigate(routes.CONFIGURATIONS_DYNAMIC_PIPELINE);
            }
          }}
        >
          Delete
        </Button>
        <div className="float-right">
          <Button secondary onClick={() => navigate(routes.CONFIGURATIONS_DYNAMIC_PIPELINE)}>
            Back
          </Button>
          <Button className="ml-3" onClick={() => saveConfiguration()} disabled={isEdit && !dynamicConfiguration}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

type SelectorModifierListProps = {
  items: {
    internalId: string;
    text: string;
    icon: ReactNode;
    actions: ReactNode[];
  }[];
};

const SelectorModifierList = ({ items }: SelectorModifierListProps) => {
  return (
    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
      {items.map(item => (
        <li
          key={`selector-modifier-item-${item.internalId}`}
          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
        >
          <div className="w-0 flex-1 flex items-center">
            {item.icon}
            <span className="ml-2 flex-1 w-0 truncate">{item.text}</span>
          </div>
          <div className="ml-4 flex-shrink-0 flex">
            {item.actions.map((actionBtn, index) => (
              <div key={`selector-modifier-action-${index}`}>
                {index > 0 ? (
                  <span className="text-gray-300 px-4" aria-hidden="true">
                    |
                  </span>
                ) : (
                  <></>
                )}
                {actionBtn}
              </div>
            ))}
          </div>
        </li>
      ))}
    </ul>
  );
};
