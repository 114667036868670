export type DynamicPipelineSelector = {
  [key: string]: {
    id: string;
    title: string;
    description?: string;
  };
};

export type DynamicPipelineModifier = DynamicPipelineSelector;

export type AppliedSelectorSchema = {
  internalId: string; // used by FE only
  id: string;
  applyTo: 'request' | 'response';
  kwargs?: { [key: string]: any };
};

export type AppliedModifierSchema = {
  internalId: string; // used by FE only
  id: string;
  kwargs?: { [key: string]: any };
};

export type AppliedModifierOrSelector = AppliedModifierSchema | AppliedSelectorSchema;

export enum NewRuleType {
  Desktop = 'desktop',
  Recorder = 'recorder',
}

export const PipelineStage = {
  downstreamRequest: 'Downstream Request',
  upstreamRequest: 'Upstream Request',
  upstreamResponse: 'Upstream Response',
  downstreamResponse: 'Downstream Response'
};

export type PipelineSchema = {
  id: string;
  applyTo: string;
  description?: string;
  priority?: number;
  ignoreGlobalConfig: boolean;
  selectors: AppliedSelectorSchema[];
  modifiers: AppliedModifierSchema[];
  ticketId?: string;
};

export type PipelineSchemaWithConfiguration = PipelineSchema & {
  configurationId: string;
  ruleType?: NewRuleType;
  enabled: boolean;
};

export type DynamicContentModConfig = {
  pipelines: PipelineSchema[];
  modifiers: any[];
  selectors: any[];
  global_config: any[];
};

export type DynamicContentModConfigSelectors = {
  apply_to_recording: boolean;
  enabled: boolean;
  desktop_clone_mode: boolean;
};

export type DynamicContentModConfigCreate = {
  configuration: DynamicContentModConfig;
  configuration_selectors: DynamicContentModConfigSelectors;
};

export type DynamicContentModConfigDocument = {
  id: string;
  configuration: DynamicContentModConfig;
  configuration_selectors: DynamicContentModConfigSelectors;
};
