const TRANSACTIONS = '/transactions';
const RANKED_TRANSACTIONS = '/transactions/ranked';
const TRANSACTION_DETAILS = '/transactions/:id';
const TRANSACTION_DIFF = '/transactions/diff/:leftId/:rightId';

const BACKGROUND_JOB_DETAILS = '/jobs/:id';
const BACKGROUND_JOBS = '/jobs';
const CACHE = '/cache';
const CACHE_CLEAR = 'cache/clear/:cacheLevel/:id';
const CONFIGURATIONS = '/configurations';
const CONFIGURATIONS_RANKING = '/configurations/ranking';
const CONFIGURATIONS_RANKING_RULE_NEW = '/configurations/ranking/:rankerType/new';
const CONFIGURATIONS_RANKING_RULE_EDIT = '/configurations/ranking/:rankerType/:id/edit';
const CONFIGURATIONS_EDITOR_RULES = '/configurations/editor';
const CONFIGURATIONS_EDITOR_RULE_NEW = '/configurations/editor/:editorRuleType/new';
const CONFIGURATIONS_EDITOR_RULE_EDIT = '/configurations/editor/:editorRuleType/:id/edit';

const CONFIGURATIONS_DYNAMIC_PIPELINE = '/configurations/pipeline';
const CONFIGURATIONS_DYNAMIC_PIPELINE_NEW = '/configurations/pipeline/:type/new';
const CONFIGURATIONS_DYNAMIC_PIPELINE_EDIT = '/configurations/pipeline/:type/:id/edit';

const CONFIGURATIONS_EXPORT = '/configurations/export';

const ROUTES = {
  RANKED_TRANSACTIONS,
  TRANSACTIONS,
  TRANSACTION_DETAILS,
  TRANSACTION_DIFF,
  BACKGROUND_JOB_DETAILS,
  BACKGROUND_JOBS,
  CACHE,
  CACHE_CLEAR,
  CONFIGURATIONS,
  CONFIGURATIONS_RANKING,
  CONFIGURATIONS_RANKING_RULE_NEW,
  CONFIGURATIONS_RANKING_RULE_EDIT,
  CONFIGURATIONS_DYNAMIC_PIPELINE,
  CONFIGURATIONS_DYNAMIC_PIPELINE_NEW,
  CONFIGURATIONS_DYNAMIC_PIPELINE_EDIT,
  CONFIGURATIONS_EDITOR_RULES,
  CONFIGURATIONS_EDITOR_RULE_NEW,
  CONFIGURATIONS_EDITOR_RULE_EDIT,
  CONFIGURATIONS_EXPORT
};

export default ROUTES;
