import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  DynamicContentModConfigCreate,
  DynamicContentModConfigDocument,
  DynamicPipelineModifier,
  DynamicPipelineSelector
} from '@src/models';
import {
  getDynamicPipelineModifiers,
  getDynamicPipelineSelectors,
  getDynamicPipelineSchema,
  createDynamicContentModConfig,
  updateDynamicContentModConfig,
  getDynamicContentModConfigs,
  getDynamicContentModConfigById,
  deleteDynamicContentModConfig
} from '@src/services';
import { v4 as uuidv4 } from 'uuid';
import { useNotifications } from '@src/hooks';

export const useGetDynamicPipelineSelectors = (isDesktopCloneMode: boolean) => {
  const { data, isLoading, error } = useQuery<DynamicPipelineSelector>(
    `dynamic-pipeline-selectors-${isDesktopCloneMode}`,
    () => getDynamicPipelineSelectors(isDesktopCloneMode), {
  }
  );

  return { data, isLoading, error };
};

export const useGetDynamicPipelineModifiers = (isDesktopCloneMode: boolean) => {
  const { data, isLoading, error } = useQuery<DynamicPipelineModifier>(
    `dynamic-pipeline-modifiers-${isDesktopCloneMode}`,
    () => getDynamicPipelineModifiers(isDesktopCloneMode)
  );

  return { data, isLoading, error };
};

export const useGetDynamicPipelineSchema = (model: string) => {
  const cacheKey = ['dynamic-pipeline-schema', model];

  const { data, isLoading, error } = useQuery<any>(cacheKey, () => getDynamicPipelineSchema(model));

  return { data, isLoading, error };
};

export const useCreateDynamicContentModConfig = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation((contentModConfig: DynamicContentModConfigCreate) =>
    createDynamicContentModConfig(contentModConfig)
      .then(response => {
        notifySuccess({
          title: 'Data rule successfully added',
          timeout: 4000
        });

        queryClient.invalidateQueries('dynamic-content-mod-configs');

        return response;
      })
      .catch(e => {
        const status = e.response.status;

        notifyError({
          title: 'Could not update data rule',
          content: status === 409 ? 'Pipeline id already exists' : 'Make sure all fields are correctly filled.'
        });

        throw e;
      })
  );

  return { mutateAsync, isLoading, error };
};

export const useUpdateDynamicContentModConfig = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation(
    ({
      contentModConfigId,
      contentModConfig
    }: {
      contentModConfigId: string;
      contentModConfig: DynamicContentModConfigCreate;
    }) =>
      updateDynamicContentModConfig(contentModConfigId, contentModConfig)
        .then(response => {
          notifySuccess({
            title: 'Data rule successfully updated',
            timeout: 4000
          });

          queryClient.invalidateQueries('dynamic-content-mod-configs');

          return response;
        })
        .catch(e => {
          const status = e.response.status;

          notifyError({
            title: 'Could not update data rule',
            content: status === 409 ? 'Pipeline id already exists' : 'Make sure all fields are correctly filled.'
          });

          throw e;
        })
  );

  return { mutateAsync, isLoading, error };
};

export const useGetDynamicContentModConfigs = () => {
  const { data, isLoading, error } = useQuery<DynamicContentModConfigDocument[]>(
    'dynamic-content-mod-configs',
    getDynamicContentModConfigs
  );

  return { data, isLoading, error };
};

export const useGetDynamicContentModConfigById = (contentModConfigId?: string) => {
  const queryClient = useQueryClient();
  const cacheKey = ['dynamic-content-mod-config', contentModConfigId];

  const invalidateDynamicContentModConfig = () => queryClient.invalidateQueries(cacheKey);

  const { data, isLoading, error } = useQuery<DynamicContentModConfigDocument | undefined>(cacheKey, () => {
    if (!contentModConfigId) return;

    return getDynamicContentModConfigById(contentModConfigId).then(res => {
      // Add internal IDs
      res.configuration.pipelines.forEach(pipeline => {
        pipeline.selectors.forEach(selector => {
          selector.internalId = uuidv4();
        });
        pipeline.modifiers.forEach(modifier => {
          modifier.internalId = uuidv4();
        });

        return pipeline;
      });

      return res;
    });
  });

  return { data, isLoading, error, invalidateDynamicContentModConfig };
};

export const useDeleteDynamicContentModConfig = () => {
  const { notifySuccess, notifyError } = useNotifications();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, error } = useMutation((contentModConfigId: string) =>
    deleteDynamicContentModConfig(contentModConfigId)
      .then(response => {
        notifySuccess({
          title: 'Data rule successfully deleted',
          timeout: 4000
        });

        queryClient.invalidateQueries('dynamic-content-mod-configs');

        return response;
      })
      .catch(e => {
        notifyError({
          title: 'Could not delete data rule'
        });

        throw e;
      })
  );

  return { mutateAsync, isLoading, error };
};
