import { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { DynamicContentModFilter } from './DynamicContentModFilter';
import { DynamicContentModList } from './DynamicContentModList';
import { useGetDynamicContentModConfigById, useGetDynamicContentModConfigs } from '@src/hooks';
import { NewRuleType, PipelineSchemaWithConfiguration } from '@src/models';
import routes from '@src/routes';
import { MenuButton } from '../../Common';
import { mapDesktopCloneModeToRuleType } from './mapRuleType';



export const NewRules: { [key: string]: string } = {
  [NewRuleType.Desktop]: "Desktop",
  [NewRuleType.Recorder]: "Recorder",
};

export const DynamicContentModPreview = () => {
  const navigate = useNavigate();

  const { data: dynamicConfigurations } = useGetDynamicContentModConfigs();
  const { invalidateDynamicContentModConfig } = useGetDynamicContentModConfigById();

  useEffect(() => {
    invalidateDynamicContentModConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [filterFunction, setFilterFunction] = useState(() => (item: PipelineSchemaWithConfiguration) => true);
  const onFilterChange = (f: (item: PipelineSchemaWithConfiguration) => boolean) => setFilterFunction(() => f);

  if (!dynamicConfigurations) return <></>;

  const dynamicPipelines = ([] as PipelineSchemaWithConfiguration[]).concat
    .apply(
      [],
      dynamicConfigurations.map(c => c.configuration.pipelines.map(p => ({ ...p, configurationId: c.id, enabled: c.configuration_selectors.enabled, ruleType: mapDesktopCloneModeToRuleType(c.configuration_selectors.desktop_clone_mode) }))) || []
    )
    .filter(filterFunction);

  return (
    <div className="space-y-6 md:h-full flex flex-col">
      <div className="bg-white flex flex-col shadow sm:rounded-lg md:h-full overflow-y-hidden">
        <div>
          <div className="px-4 py-5 sm:p-6 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Data Rules</h3>
              <p className="mt-1 text-sm text-gray-500">
                Lets you modify requests and responses during recording and playback.
              </p>
            </div>
            <div>
              <MenuButton
                title="New Rule"
                items={Object.entries(NewRules).map(([type, label]) => ({
                  name: label,
                  route: generatePath(routes.CONFIGURATIONS_DYNAMIC_PIPELINE_NEW, { type })
                }))}
              />
            </div>
          </div>
          <DynamicContentModFilter onFilterChange={onFilterChange} />
        </div>
        <DynamicContentModList
          pipelines={dynamicPipelines}
          onItemClick={item =>
            navigate(generatePath(routes.CONFIGURATIONS_DYNAMIC_PIPELINE_EDIT, { id: item.configurationId, type: item.ruleType }))
          }
        />
      </div>
    </div>
  );
};
