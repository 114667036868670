import {
  DynamicContentModConfigCreate,
  DynamicContentModConfigDocument,
  DynamicPipelineModifier,
  DynamicPipelineSelector
} from '@src/models';
import request, { apiRequest } from '../request';

const API_ENDPOINTS = {
  DYNAMIC_CONTENT_MOD: '/content-mod',
  GET_SELECTORS: '/content-mod/selectors',
  GET_MODIFIERS: '/content-mod/modifiers',
  PIPELINE_SCHEMA: '/content-mod/schema'
};

export const getDynamicPipelineSelectors = async (isDesktopCloneMode: boolean): Promise<DynamicPipelineSelector> =>
  (await apiRequest(request.get(API_ENDPOINTS.GET_SELECTORS, {
    params: {
      isDesktopCloneMode
    }
  }))).content;

export const getDynamicPipelineModifiers = async (isDesktopCloneMode: boolean): Promise<DynamicPipelineModifier> =>
  (await apiRequest(request.get(API_ENDPOINTS.GET_MODIFIERS, {
    params: {
      isDesktopCloneMode
    }
  }))).content;

export const getDynamicPipelineSchema = async (model: string): Promise<any> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.PIPELINE_SCHEMA}/${model}`))).content;

export const createDynamicContentModConfig = async (
  contentModConfig: DynamicContentModConfigCreate
): Promise<DynamicContentModConfigDocument> => {
  return (await apiRequest(request.post(API_ENDPOINTS.DYNAMIC_CONTENT_MOD, contentModConfig))).content;
};

export const updateDynamicContentModConfig = async (
  contentModConfigId: string,
  contentModConfig: DynamicContentModConfigCreate
): Promise<DynamicContentModConfigDocument> => {
  return (await apiRequest(request.put(`${API_ENDPOINTS.DYNAMIC_CONTENT_MOD}/${contentModConfigId}`, contentModConfig)))
    .content;
};

export const getDynamicContentModConfigs = async (): Promise<DynamicContentModConfigDocument[]> =>
  (await apiRequest(request.get(API_ENDPOINTS.DYNAMIC_CONTENT_MOD))).content;

export const getDynamicContentModConfigById = async (
  contentModConfigId: string
): Promise<DynamicContentModConfigDocument> =>
  (await apiRequest(request.get(`${API_ENDPOINTS.DYNAMIC_CONTENT_MOD}/${contentModConfigId}`))).content;

export const deleteDynamicContentModConfig = async (contentModConfigId: string): Promise<void> =>
  (await apiRequest(request.delete(`${API_ENDPOINTS.DYNAMIC_CONTENT_MOD}/${contentModConfigId}`))).content;
