import { Breadcrumb, BreadcrumbItem, DynamicPipelineForm } from '@src/components';
import { NewRuleType } from '@src/models';
import routes from '@src/routes';
import { useParams } from 'react-router';

export const DynamicContentModDetailsPage = ({ isEdit }: { isEdit?: boolean }) => {
  const params = useParams<{ id: string, type: NewRuleType }>() as { id: string, type: NewRuleType };

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem to={routes.CONFIGURATIONS} label="Configurations" />
        <BreadcrumbItem to={routes.CONFIGURATIONS_DYNAMIC_PIPELINE} label="Data Rules" />
        <BreadcrumbItem to={window.location.pathname} label={isEdit ? params.id : `New ${params.type.toLowerCase()} rule`} />
      </Breadcrumb>
      <div className="py-6">
        <DynamicPipelineForm isEdit={isEdit} />
      </div>
    </>
  );
};
